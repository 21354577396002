<template>
  <div class="main">
    <van-nav-bar class="cnavbar" title="积分兑换" left-arrow @click-left="handleBack" />

    <div class="content">
      <!-- 用户信息 -->
      <div class="user-info">
        <div class="user-info-header">
          <div>
            <van-image
              fit="cover"
              class="account"
              :src="require('../../static/images/account.png')"
            />
          </div>
          <div>
            <van-row class="">
              <van-col span="24" class="account-mobile">
                <span>{{ account }}</span>
              </van-col>
            </van-row>
            <van-row class="">
              <van-col span="24" class="account-descr">
                <van-image
                  fit="cover"
                  class="account-descr-picon"
                  :src="require('../../static/images/v3/jifen.png')"
                />
                <span><span class="account-descr-points">{{ points }}</span></span>
              </van-col>
            </van-row>
          </div>
        </div>
      </div>
    </div>

    <!-- 积分兑换选择 -->
    <div class="exchange">
      <div class="exchange-steper">
        <van-row class="">
          <van-image
            fit="cover"
            class="exchange-labtip"
            :src="require('../../static/images/v3/label-point.png')"
          />
        </van-row>
        <van-row class="">
          <van-col span="24" class="exchange-tip">
            {{ productInfo.goodsName }}
          </van-col>
        </van-row>

       <div style="border-radius: 5px; background-color: white;">
         <div class="exchange-input">
          <van-image
            fit="cover"
            class="exchange-icon"
            :src="require('../../static/images/v2/icon_jian.png')"
            @click="handleMinus"
          />

          <!-- <van-field
            v-model="exchangeVal"
            size="large"
            input-align="center"
            placeholder=""
            class="exchange-val"
          /> -->
          <!-- <span class="exchange-val-symbol" style="padding-right: 5px;">价值</span> -->
          <span class="exchange-val"><span style="padding-right: 5px;">价值</span>{{ exchangeVal * 2 }}<span >元</span></span>

          <van-image
            fit="cover"
            class="exchange-icon"
            :src="require('../../static/images/v2/icon_jia.png')"
            @click="handleAdd"
          />
        </div>
        <div style="text-align: center;color: #FF533C; font-weight: 300;margin-top: -10px;padding-bottom: 10px;">
          (含{{ exchangeVal }}元立减券+{{ exchangeVal }}元延时券)
        </div>
       </div>
        <div v-if="needPoints > 0" class="exchange-need">
          <span class="exchange-need-title">消耗积分：</span><span class="exchange-need-val">{{ needPoints }}</span>
        </div>

        <van-row>
          <van-col span="24" class="exchange-submit">
            <van-button
              class="exchange-btn"
              color="#FF533C"
              @click="handleConfirm"
            >
              确定
            </van-button>
          </van-col>
        </van-row>

        <!-- <van-row>
          <van-col span="24" class="exchange-record">
            <span>兑换记录</span>
          </van-col>
        </van-row> -->
      </div>
    </div>

    <van-popup
      v-model="showPopup"
      position="center"
      :close-on-click-overlay="false"
      :style="{ minHeight: '350px', width: '80%' }"
      round
    >
      <div class="pop">
        <!-- header -->
        <div class="pop-header">
          <div class="pop-header-title">短信验证</div>
        </div>

        <!-- step -->
        <!-- <div class="pop-process">
          <van-steps
            class="pop-step"
            :active="active"
            active-icon="success"
            active-color="#f13c3c"
          >
            <van-step>移动积分</van-step>
            <van-step>畅由积分</van-step>
            <van-step>互联赏金</van-step>
          </van-steps>
        </div> -->

        <!-- button -->
        <div style="padding-top: 20px">
          <div class="pop-title">
            <span class="pop-title-lab">验证码确认</span>
          </div>
          <div class="pop-info">
            <van-image
              fit="cover"
              class="pop-icon"
              :src="require('../../static/images/mobile-phone.png')"
            />
            <span class="pop-mobile">{{ account }}</span>
          </div>
          <div class="pop-info">
            <van-image
              fit="cover"
              class="pop-icon"
              :src="require('../../static/images/vcode.png')"
            />
            <van-field
              v-model="smsCode"
              size="large"
              placeholder="请输入验证码"
              class="pop-val"
              maxlength="6"
              clearable
            >
              <template #button>
                <van-button
                  size="small"
                  @click="handleSendSMS"
                  :disabled="isCountDown"
                  >{{ countdownLab }}</van-button
                >
              </template>
            </van-field>
          </div>
        </div>

        <div class="pop-bottom">
          <van-button
            class="pop-btn"
            round
            color="#FF533C"
            @click="handlePayConfirm"
          >
            确认兑换
          </van-button>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model="showResPopup"
      position="center"
      :close-on-click-overlay="false"
      :style="{ minHeight: '360px', width: '80%' }"
      round
    >
      <div class="pop-result">
        <van-image
          fit="cover"
          class="pop-result-header"
          :src="require('../../static/images/success_icon.png')"
        />

        <van-image
          fit="cover"
          class="pop-result-icon"
          :src="require('../../static/images/success_gou_icon.png')"
        />

        <van-image
          fit="cover"
          class="pop-result-lab"
          :src="require('../../static/images/success-icon.png')"
        />

        <div class="pop-result-submit" @click="handleOk">确认</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  Row,
  Col,
  NavBar,
  Image,
  Button,
  Field,
  Popup,
  Step,
  Steps,
} from "vant";
import {
  memberInfo,
  queryCmccBalance,
  getProduct,
  placeOrder,
  dectOrder,
  sendCmccSms,
} from "@/api/global";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [NavBar.name]: NavBar,
    [Image.name]: Image,
    [Button.name]: Button,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Step.name]: Step,
    [Steps.name]: Steps,
  },

  data() {
    return {
      account: null,
      points: 0,
      exchangeVal: 0,
      showPopup: false,
      showResPopup: false,
      smsCode: null,
      active: 0,
      stepVal: 0,
      buyCount: 1,
      needPoints: 0,
      productInfo: {},
      reqOrderId: null,
      isCountDown: false,
      countdownLab: "发送验证码",
      timer: null,
    };
  },

  created() {
    this.account = localStorage.getItem("dd_mobile");
    this.initLoadData()

    console.log("code >>> ", this.$route.query.channelCode)
  },

  mounted() {
    this.initLoadData()
  },

  computed: {
    submitBarText() {
      const count = this.checkedGoods.length;
      return "结算" + (count ? `(${count})` : "");
    },

    totalPrice() {
      return this.goods.reduce(
        (total, item) =>
          total + (this.checkedGoods.indexOf(item.id) !== -1 ? item.price : 0),
        0
      );
    },
  },

  methods: {
    initLoadData() {
      memberInfo().then((res) => {
        console.log(res);
      });

      let data = {}
      if (this.$route.query.channelCode) {
        data.channelCode = this.$route.query.channelCode
      }

      if (this.$route.query.id) {
        data.transCode = this.$route.query.id
      }

      if (this.$route.query.ddback) {
        data.ddback = this.$route.query.ddback
      }

      queryCmccBalance(data).then((res) => {
        console.log("queryCmccBalance >>> ", res);
        // if (res.resultCode && res.resultCode == "B002") {
        if (res.resultCode != '9999' && res.authUrl && res.authUrl.length > 0) {
          // window.location = "https://test-m-stg3.ppppoints.com/event/2019/blankPage/index.html?interCode=CYS0001&character=00&ipAddress=172.110.1.20&partnerId=S9999073&requestId=8888888888888888888&reqTime=20220712&signType=MD5&type=mobile&version=1.0.0&mobile=13584778906&outTokenId=S99999913584778906_out&outType=00&callbackUrl=http%3A%2F%2Fwww.njjiaoteng.com%2Fredirect.do&channelSource=02000000&reserved1=&reserved2=&hmac=fcf73165a6d9af2df41055d7406cc5ec";
          window.location = `${res.authUrl}`;
        } else {
          this.points = res.points || 0;

          this.calcExchangeVal()
        }
      });

      getProduct({
        pageNo: 1,
        pageSize: 1000,
      }).then((res) => {
        console.log("res >>>> ", res);
        if (res.records && res.records.length > 0) {
          this.productInfo = res.records[0];
          this.stepVal = this.productInfo.threeValue;
          this.exchangeVal = this.productInfo.threeValue;
          this.needPoints = this.productInfo.points;
          this.buyCount = 1;

          this.calcExchangeVal()
        }
      });
    },

    formatPrice(price) {
      return (price / 100).toFixed(2);
    },

    startCountDown() {
      const _t = this;
      let initVal = 60;
      this.timer = setInterval(() => {
        if (initVal <= 0) {
          _t.isCountDown = false;
          _t.countdownLab = "获取验证码";

          clearInterval(_t.timer);
          return;
        }

        initVal -= 1;
        _t.countdownLab = initVal + "s";
      }, 1000);
    },

    // 减
    handleMinus() {
      this.exchangeVal =
        parseInt(this.exchangeVal || 0) - parseInt(this.stepVal);

      if (this.exchangeVal < 0) {
        this.exchangeVal = 0;
        return;
      }

      this.buyCount = parseInt(this.exchangeVal) / parseInt(this.stepVal);
      this.needPoints = this.buyCount * this.productInfo.points
    },

    // 加
    handleAdd() {
      this.exchangeVal =
        parseInt(this.exchangeVal || 0) + parseInt(this.stepVal);

      this.buyCount = parseInt(this.exchangeVal) / parseInt(this.stepVal);
      this.needPoints = this.buyCount * this.productInfo.points

      if (this.needPoints > this.points) {
        this.exchangeVal = parseInt(this.exchangeVal) - parseInt(this.stepVal);
        this.$toast("当前可用积分不足！");

        if (this.exchangeVal < 0) {
          this.exchangeVal = 0;
        }

        this.buyCount = parseInt(this.exchangeVal) / parseInt(this.stepVal);
        this.needPoints = this.buyCount * this.productInfo.points
      }
    },

    calcExchangeVal() {
      if (this.productInfo && this.productInfo.points > 0) {
        let currentPoints = parseInt(this.points) > 24000 ? 24000 : parseInt(this.points)

        this.buyCount = parseInt(parseInt(currentPoints) / parseInt(this.productInfo.points));
        this.exchangeVal = this.buyCount * this.stepVal

        this.needPoints = this.buyCount *  this.productInfo.points
      }else {
        this.buyCount = 0
        this.exchangeVal = 0
        this.needPoints = 0
      }

    },

    handleBack() {
      this.$router.back();
    },

    handleOk() {
      this.$router.replace("/");
    },

    handleSendSMS() {
      if (!this.reqOrderId) {
        this.$toast("无法查询订单信息，请稍后再试");
        return;
      }

      sendCmccSms({
        reqOrderId: this.reqOrderId,
      }).then(() => {
        this.isCountDown = true;
        this.startCountDown();
      });
    },

    handleConfirm() {
      console.log("sid >>> ", window.sessionId, window.blackbox);

      if (!window.sessionId) {
        this.$toast("服务初始异常");
        return;
      }

      if (!window.blackbox) {
        this.$toast("服务初始异常");
        return;
      }

      if (!this.productInfo.goodsNo) {
        this.$toast("商品数据获取异常，请刷新");
        return;
      }

      if (this.buyCount <= 0) {
        this.$toast("兑换数量不能少于0");
        return;
      }

      if (this.needPoints > this.points){
        this.$toast("当前可用积分不足，无法兑换！");
        return;
      }

      let data = {
        fingerprint: window.blackbox,
        goodsNo: this.productInfo.goodsNo,
        num: this.buyCount,
        sessionid: window.sessionId,
      }

      if (this.$route.query.channelCode) {
        data.channelCode = this.$route.query.channelCode
      }

      if (this.$route.query.id) {
        data.transCode = this.$route.query.id
      }

      placeOrder(data).then((res) => {
        if (res) {
          this.reqOrderId = res.requestId;
          this.isCountDown = true;
          this.startCountDown();

          this.showPopup = true;
        }
      });
    },

    handlePayConfirm() {
      if (!this.reqOrderId) {
        this.$toast("无法查询订单信息，请稍后再试");
        return;
      }

      if (!this.smsCode || this.smsCode.length == 0) {
        this.$toast("请输入验证码");
        return;
      }

      dectOrder({
        fingerprint: window.blackbox,
        reqOrderId: this.reqOrderId,
        smsCode: this.smsCode,
        sessionid: window.sessionId,
      }).then(() => {
        let query = {}
        if (this.$route.query.channelCode) {
          query.channelCode = this.$route.query.channelCode
        }

        if (this.$route.query.id) {
          query.id = this.$route.query.id
        }

        if (this.$route.query.ddback) {
          query.ddback = this.$route.query.ddback
        }

        this.$router.push({
          path: "/res",
          query
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  background-color: #fff;
  min-height: 100vh;
}

:deep(.van-nav-bar__content) {
  background-color: #FF533C;

}

:deep(.van-nav-bar__title) {
  color: #fff !important;
}

:deep(.van-icon) {
  color: #fff !important;
}

.content {
  position: relative;


  &-header {
    width: 100%;
    height: 200px;
  }
}

.user-info {
  background-color: #fff;
  box-shadow: 0px 2px 8px #ddd;
  border-radius: 10px;
  margin: 20px 20px 10px;
  padding: 20px;

  &-header {
    display: flex;
    align-items: center;
  }
}

.account-mobile {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.account {
  width: 55px;
  height: 55px;
  padding-right: 10px;
}

.account-descr {
  color: #666;
  font-size: 13px;
  padding-top: 8px;
  display: flex;
  align-items: center;

  &-picon {
    width: 15px;
    height: 15px;
  }

  &-points {
    color: #FF533C;
    font-size: 14px;
    font-weight: 600;
    padding-left: 5px;
  }
}

.points {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;

  &-title {
    display: flex;
    align-items: center;

    &-icon {
      width: 20px;
      height: 15px;
    }

    &-lab {
      padding-left: 5px;
      font-size: 12px;
      color: rgba(255, 255, 255, 1);
    }
  }

  &-count {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    padding-top: 5px;
  }
}

.exchange {
  &-steper {
    position: relative;
    margin: 15px 30px 10px;
    border-radius: 10px;
    // background-color: antiquewhite;
    background-image: url("../../static/images/v3/bg_coupon.png");
    background-size: 100% 100%;
    min-height: 350px;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-tip {
    background-color: #FF533C;;
    border-radius: 5px;
    color: #fff;
    padding: 5px 15px;
    margin: 20px 0;
    font-size: 14px;
    text-align: center;
  }

  &-icon {
    width: 24px;
    height: 24px;
  }

  &-input {
    padding: 5px 30px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #fff;
  }

  &-labtip {
    max-width: 220px;
    margin-top: 15px;
  }

  &-val {
    min-width: 140px;
    max-width: 200px;
    font-size: 28px;
    font-weight: 600;
    background-color: transparent;
    color: #FF533C;
    margin: 0 20px;
    text-align: center;

    &-symbol{
      font-size: 8px;
      padding-left: 5px;
    }
  }

  &-submit {
    position: absolute;
    left: 0;
    bottom: 40px;
    display: flex;
    justify-content: center;
  }

  &-btn {
    width: 250px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
  }


  &-record {
    margin-top: 60px;
    padding-bottom: 20px;
    font-size: 14px;
    color: #666;
    text-decoration: underline;
  }

  &-need {
    font-size: 13px;
    width: 65%;
    text-align: left;
    margin-top: 10px;

    &-title {
      color: #FF533C;
    }

    &-val {
      color: #FF533C;;
    }
  }
}

.pop {
  position: relative;
  height: 100%;
  background-color: #fff;

  &-header {
    background-color: #FF533C;
    color: #fff;
    height: 48px;
    width: 100%;

    &-title {
      width: 100%;
      height: 48px;
      line-height: 48px;
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
    }
  }

  &-process {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-step {
    width: 70%;
  }

  &-info {
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid #ddd;
  }

  &-mobile {
    padding: 15px 0px;
    padding-left: 10px;
    font-size: 17px;
    font-weight: 600;
  }

  &-icon {
    width: 25px;
    height: 25px;
    padding-left: 20px;
  }

  &-val {
    font-size: 17px;
    font-weight: 600;
  }

  &-title {
    &-lab {
      margin-top: 30px;
      color: #666;
      padding: 0 20px;
      font-size: 13px;
    }
  }

  &-bottom {
    padding-top: 30px;
    display: flex;
    justify-content: center;
  }

  &-btn {
    width: 80%;
  }
}

.pop-result {
  position: relative;

  &-header {
    width: 100%;
    z-index: 99;
  }

  &-icon {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 199;
  }

  &-lab {
    position: absolute;
    top: 250px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 199;
  }

  &-submit {
    position: absolute;
    width: 100%;
    bottom: -120px;
    font-size: 14px;
    color: #666;
    text-align: center;
  }
}

:deep(.van-field__control) {
  color: #F56C13;
}
</style>
